/**
 * Vue custom filters
 */

// comma separate long numerical values
export function toCommas ( num, decimals ) {
  let o = { style: 'decimal', minimumFractionDigits: decimals, maximumFractionDigits: decimals };
  //console.log("num"+num);
  return new Intl.NumberFormat( 'en-US', o ).format( num );
}

// sanitize text data by only allowing alnums and some symbols
export function toText ( str, def ) {
  //str = String( str || '' ).replace( /[^\w\`\'\-\,\.\!\?]+/g, ' ' ).replace( /\s\s+/g, ' ' ).trim();
  //console.log("toText before "+str);
  const str_filtered = String( str || '' ).replace( /[^\w\\`\\'\\-\\,\\.\\!\\?]+/g, ' ' ).replace( /\s\s+/g, ' ' ).trim();
  //console.log("toText after "+str_filtered);
  return str_filtered || String( def || '' );

}

export function host (url) {
  const host = url.replace(/^https?:\/\//, '').replace(/\/.*$/, '')
  const parts = host.split('.').slice(-3)
  if (parts[0] === 'www') parts.shift()
  return parts.join('.')
}