<template>
  <!-- app root container -->
  <div id="app" class="app-wrap">

      <!-- app player container -->
      <main id="player-wrap" class="player-wrap" style="opacity: 0;">
        <!-- bg absolute elements -->
        <figure id="player-bg" class="player-bg" style="background-image: url(  https://radiotel.org/img/radio-background.jpg );"></figure>
        <canvas id="player-canvas" class="player-canvas"></canvas>

        <!-- main player layout -->
        <section class="player-layout">

          <!-- player top header -->
          <header class="player-header flex-row flex-middle flex-stretch">
            <h1 class="text-clip flex-1"><button @click="gotoHome"><i class="fa fa-phone"></i> Radio la telefon</button></h1>
            <button class="text-nowrap common-btn" @click="toggleSidebar( true )"><i class="fa fa-bars"></i></button>
          </header>

          <!-- player middle content area -->
          <main class="player-content flex-row">

            <!-- default greet message -->
            <section class="player-greet" v-if="!hasChannel && !hasError">
              <div class="fx fx-slide-left push-bottom"><h2>Posturi de radio din Romania</h2></div>
              <div class="fx fx-slide-left fx-delay-1 push-bottom">Alegi postul de radio, suni la numarul de telefon afisat si asculti LIVE. <br><br><i>Apelul este inclus in minutele nationale si nu are suprataxa, depinzand doar de planul tarifar al abonamentului.<br> In cazul in care abonatul nu beneficiaza de minute nationale, apelul va genera costuri suplimentare.</i></div>
              <div class="fx fx-slide-up fx-delay-2 pad-top"><button class="cta-btn" @click="toggleSidebar( true )"><i class="fa fa-phone">&nbsp;</i> Vezi posturile de radio</button></div>
            </section>

            <!-- show selected channel info if possible -->
            <section class="player-channel flex-1" v-if="hasChannel && !hasError" :key="channel.id">
              <div class="flex-autorow flex-top flex-stretch">

                <!-- station details -->
                <div class="flex-item flex-1">

                  <!-- station -->
                  <div class="push-bottom">
                    <div class="flex-row flex-middle">
                      <img class="img-round fx fx-drop-in fx-delay-1" :src="channel.image" width="80" height="80" :alt="channel.nume" />
                      <div class="pad-left fx fx-slide-left fx-delay-2">
                        <h2 class="text-clip">{{ channel.nume }}</h2>
                        <div class="text-nowrap">
                          <span class="text-clip text-uppercase" >{{ channel.slogan }} &nbsp;</span>
                          <fav-btn :id="channel.id" :active="channel.favorite" @change="toggleFavorite"></fav-btn>
                        </div>
                        <div class="text-nowrap">
                          <span class="text-faded text-uppercase text-small"> {{ channel.gen | toText }} </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- description -->
                  <div class="card push-bottom fx fx-slide-up fx-delay-3">
                    <div class="text-secondary">Telefon {{  mask_tel_pct(channel.telefon) | toText( 'N/A' ) }}</div>
                    <div>Postul de radio se poate asculta LIVE si international la  {{ mask_tel_intl(channel.telefon) | toText( 'N/A' ) }}  </div>
                  </div>

                  <!-- current track -->
                  <div class="card push-bottom fx fx-slide-up fx-delay-4" :key="track.date" v-show="hasMelodii">
                    <div><span class="text-secondary">LIVE ACUM</span></div>
                    <div><span class="text-faded"></span> <span class="text-bright">{{ track.titlu | toText( 'N/A' ) }}</span></div>
                    <div><span class="text-faded"></span> <span class="text-default">{{ track.artist | toText( 'N/A' ) }}</span></div>
                  </div>

                  <!--  <vue-plyr-audio /> -->

                  <!-- buttons -->
                  <div class="push-bottom text-nowrap">
                    <a class="cta-btn text-nowrap fx fx-slide-up fx-delay-5" :href="channel.infourl" title="ascultatori on-line" target="_blank" v-show="channel.listeners>0">
                      <span class="fx fx-notx fx-ibk fx-drop-in" :key="channel.listeners"><i class="fa fa-phone"></i> {{ channel.listeners | toCommas( 0 ) }}</span>
                    </a> &nbsp;
                    <a class="cta-btn text-nowrap fx fx-slide-up fx-delay-6" :href="channel.facebook" title="pagina Facebook" target="_blank" v-show="channel.facebook != ''">
                      <i class="fa fa-facebook"></i>
                    </a> &nbsp;
                    <a class="cta-btn text-nowrap fx fx-slide-up fx-delay-6" :href="channel.twitter" title="pagina Twitter" target="_blank" v-show="channel.twitter != ''">
                      <i class="fa fa-twitter"></i>
                    </a> &nbsp;
                  </div>

                </div>

                <!-- melodii list -->
                <div class="flex-item flex-1" v-show="hasMelodii">
                  <div class="push-bottom">
                    <h4 class="text-nowrap fx fx-slide-left fx-delay-1">Ultimele melodii</h4>
                  </div>
                  <div class="card push-bottom" v-if="!hasMelodii">
                    Nu exista melodii incarcate inca pentru aceast post de radio.
                  </div>
                  <!--
                  <ul class="player-tracklist push-bottom" v-if="hasMelodii">
                  <ul class="player-tracklist flex-row flex-top flex-stretch" v-if="hasMelodii">
                  -->
                  <ul class="player-tracklist push-bottom" v-if="hasMelodii">
                    <li v-for="( s, i ) of melodiiList" :key="s.date" class="card fx" :class="'fx-slide-left fx-delay-' + ( i + 2 )">
                      <div><span class="text-secondary">{{ s.titlu | toText( 'N/A' ) }}</span></div>
                      <div><span class="text-bright">{{ s.artist | toText( 'N/A' ) }}</span></div>
                      <div v-show="false"><span class="text-faded">Album:</span> <span class="text-default">{{ s.album | toText( 'N/A' ) }}</span></div>
                    </li>
                  </ul>
                </div>

              </div>
            </section>

            <!-- show tracks for selected channel if possible -->
            <section class="player-errors flex-1 text-center" v-if="hasError" key="errors">
              <div class="push-bottom fx fx-drop-in fx-delay-1">
                <i class="fa fa-plug text-huge text-faded"></i>
              </div>
              <div class="fx fx-slide-up fx-delay-2">
                <h3>Hopa, e o problema!</h3>
              </div>
              <hr />
              <div v-for="( e, i ) of errors" class="push-bottom fx fx-slide-up" :class="'fx-delay-' + ( i + 3 )"  :key="i">
                <span class="text-primary">{{ e }}</span>
              </div>
              <hr />
              <button class="cta-btn text-nowrap fx fx-slide-up fx-delay-4" @click="tryAgain">
                <i class="fa fa-refresh"></i> Incearca din nou
              </button>
            </section>

          </main>

          <!-- player footer with controls -->
          <footer class="player-footer flex-row flex-middle flex-space">

            <!-- player controls -->
            <section class="player-controls flex-row flex-middle push-right" :class="{ 'disabled': !canPlay }">
              <button class="common-btn" @click="togglePlay()">
                <i v-if="loading" class="fa fa-circle-o-notch fx fx-spin-right" key="wait"></i>
                <i v-else-if="playing" class="fa fa-stop fx fx-drop-in" key="stop"></i>
                <i v-else class="fa fa-play fx fx-drop-in" key="play"></i>
              </button>
              <div class="form-slider push-left">
                <i class="fa fa-volume-down"></i>
                <input class="common-slider" type="range" min="0.0" max="1.0" step="0.1" value="0.8" v-model="volume" />
                <i class="fa fa-volume-up"></i>
              </div>
              <div class="text-clip push-left">
                <span>{{ timeDisplay }}</span>
                <span class="text-faded" v-if="hasChannel">&nbsp;|&nbsp;</span>
                <span class="fx fx-fade-in fx-delay-1" v-if="hasChannel" :key="channel.id">{{ channel.nume }}</span>
                <span  v-show="false" class="text-faded" v-if="hasChannel">&nbsp;|&nbsp;</span>
                <span v-show="false" class="text-faded text-uppercase text-small"> {{ channel.gen | toText }} </span>
              </div>
            </section>

            <!-- player links -->
            <section class="player-links text-nowrap">
              <a class="common-btn text-faded" href="https://www.facebook.com/radiolatelefon" title="Viziteaza-ne pe Facebook" target="_blank">
                <i class="fa fa-facebook"></i>
              </a> &nbsp;
              <a class="common-btn text-faded" href="https://twitter.com/radiolatelefon" title="Viziteaza-ne pe Twitter" target="_blank">
                <i class="fa fa-twitter"></i>
              </a>&nbsp;
              <a class="common-btn text-faded" href="https://www.instagram.com/radiotel" title="Viziteaza-ne pe Instagram" target="_blank">
                <i class="fa fa-instagram"></i>
              </a> &nbsp;
              <a class="common-btn text-faded" href="mailto:info@radiotel.org" title="Trimite-ne un email" target="_blank">
                <i class="fa fa-envelope"></i>
              </a>&nbsp;
            </section>

          </footer>

        </section> <!-- layout wrapper -->

        <!-- player stations overlay + sidebar -->
        <section class="player-stations" :class="{ 'visible': sidebar }" @click="toggleSidebar( false )">
          <aside class="player-stations-sidebar" @click.stop>

            <!-- sidebar search -->
            <header class="player-stations-header flex-row flex-middle flex-stretch">
              <div class="form-input push-right">
                <i class="fa fa-search"></i>
                <input type="text" placeholder="Cauta posturi..." v-model="searchText" />
              </div>
              <button class="common-btn" @click="toggleSidebar( false )"><i class="fa fa-times-circle"></i></button>
            </header>

            <!-- sidebar stations list -->
            <ul class="player-stations-list">
              <li class="player-stations-list-item flex-row flex-top flex-stretch" v-for="c of channelsList" :key="c.id" @click="setRoute( c.route )" :class="{ 'active': c.active }">
                <figure class="push-right if-small">
                  <a v-bind:href="c.route"  ><img class="img-round" width="70" height="70" :src="c.image" :alt="c.nume" /></a>
                </figure>
                <aside class="flex-1">
                  <div class="flex-row flex-middle flex-space">
                    <div class="player-stations-list-title text-bright text-clip">{{ c.nume }}</div>
                    <div class="text-nowrap">
                      <span class="text-secondary"  v-show="channel.listeners>0"><i class="fa fa-phone"></i> {{ c.listeners | toCommas( 0 ) }} &nbsp;</span>
                      <fav-btn :id="c.id" :active="c.favorite" @change="toggleFavorite"></fav-btn>
                    </div>
                  </div>
                  <div class="text-small">
                    <span class="text-secondary text-small">Telefon {{  mask_tel_pct(c.telefon) | toText( 'N/A' ) }}</span> <br />
                    {{ c.slogan }}
                  </div>
                </aside>
              </li>
            </ul>

            <!-- sidebar sort options -->
            <footer class="player-stations-footer flex-row flex-middle flex-stretch">
              <div class="flex-1 push-right">
                <span @click="toggleSortOrder()" class="fa clickable" :class="{ 'fa-sort-amount-desc': sortOrder === 'desc', 'fa-sort-amount-asc': sortOrder === 'asc' }">&nbsp;</span>
                <span class="text-faded">Sorteaza: &nbsp;</span>
                <span class="text-bright popover">
                  <span class="clickable">{{ sortLabel }}</span>
                  <span class="popover-box popover-top">
                    <button @click="sortBy( 'nume', 'asc' )"><i class="text-faded fa fa-font">&nbsp;</i> Nume post</button>
                    <button @click="sortBy( 'ascultatori', 'desc' )"><i class="text-faded fa fa-phone">&nbsp;</i> Top ascultatori</button>
                    <button @click="sortBy( 'favorite', 'desc' )"><i class="text-faded fa fa-heart">&nbsp;</i> Favorite salvate</button>
                    <button @click="sortBy( 'telefon', 'asc' )"><i class="text-faded fa fa-music">&nbsp;</i> Nr. telefon</button>
                  </span>
                </span>
              </div>
            </footer>

          </aside>
        </section>

      </main> <!-- player -->
  </div>
</template>

<script>
import './scss/app.scss';
import _radiotel from './utils/radiotel';
import _audio from './utils/audio';
import _utils from './utils/utils';
import _store from './utils/store';

//    <FavBtn :id="channel.id" :active="channel.favorite" @change="toggleFavorite"/>
import FavBtn from './components/FavBtn.vue'
//import VuePlyrAudio from './components/audio.vue';

export default {
  name: 'App',
  components: {
//    VuePlyrAudio,
    FavBtn
  },
data() {
  return {
    // toggles
    init: false,
    visible: true,
    playing: false,
    loading: false,
    sidebar: false,
    volume: 0.8,
    // channels stuff
    channels: [],
    channel: {},
    melodii: [],
    track: {},
    favorites: [],
    errors: {},
    // timer stuff
    timeStart: 0,
    timeDisplay: '00:00:00',
    timeItv: null,
    // sorting stuff
    searchText: '',
    sortParam: 'listeners',
    sortOrder: 'desc',
    // timer stuff
    anf: null,
    sto: null,
    itv: null,
    }
  },
  // watch methods
  watch: {

    // watch playing status
    playing() {
      if ( this.playing ) { this.startClock(); }
      else { this.stopClock(); }
    },

    // update player volume
    volume() {
      _audio.setVolume( this.volume );
    }
  },

  // computed methods
  computed: {

    // filter channels list
    channelsList() {
      let list = this.channels.slice();
      let lista_noua = this.channels.slice();
      //let search = this.searchText.replace( /[^\w\s\-]+/g, '' ).replace( '/[\r\s\t\n]+/g', ' ' ).trim();
      let search = this.searchText.replace(  /[^\w\s]+/g, '' ).replace( /[\r\s\t\n]+/g, ' ' ).trim();
      if ( search && search.length > 1 ) {
        lista_noua = _utils.search( list, 'nume', search );
        if(lista_noua.length == 0)//incercam alt criteriu de cautare
          lista_noua = _utils.search( list, 'slogan', search );
        if(lista_noua.length == 0)//incercam alt criteriu de cautare
          lista_noua = _utils.search( list, 'telefon', search );
        if(lista_noua.length == 0)//incercam alt criteriu de cautare
          lista_noua = _utils.search( list, 'gen', search );
      }
      //if(process.env.NODE_ENV === "development")
        //console.log(lista_noua.length);
      if ( this.sortParam ) {
        lista_noua = _utils.sort( lista_noua, this.sortParam, this.sortOrder, true );
      }
      if ( this.channel.id ) {
        lista_noua = lista_noua.map( i => {
          i.active = ( this.channel.id === i.id ) ? true : false;
          return i;
        });
      }
      return lista_noua;
    },

    // filter melodii list
    melodiiList() {
      let list = this.melodii.slice();
      return list;
    },

    // sort-by label for buttons, etc
    sortLabel() {
      switch ( this.sortParam ) {
        case 'nume'         : return 'Nume post';
        case 'ascultatori'  : return 'Top ascultatori';
        case 'favorite'     : return 'Favorite salvate';
        case 'telefon'      : return 'Nr. telefon';
      }
  return 'implicit';
    },

    // check if audio can be played
    canPlay() {
      return ( this.channel.id && !this.loading ) ? true : false;
    },

    // check if a channel is selected
    hasChannel() {
      return this.channel.id ? true : false;
    },

    // check if there are tracks loaded
    hasMelodii() {
      return this.melodii.length ? true : false;
    },

    // check for errors that would affect playback
    hasError() {
      if ( this.errors.support || this.errors.stream ) return true;
      if ( this.errors.channels && !this.channels.length ) return true;
      //if ( this.errors.stream ) return true;
      return false;
    },
  },

  // custom methods
  methods: {

    changeMessage: function() {
      if(process.env.NODE_ENV === "development")
        console.log("Vue.js is awesome!");
    },

    // afiseaza nr de telefon cu .... sau spatii
    mask_tel_pct( nr_tel ) {
      var formatted = nr_tel.toString().replace(/^(\d{4})(\d{3})(\d{3}).*/, '$1 $2 $3')
      return formatted ;
    },

    // afiseaza nr de telefon cu 004 inainte
    mask_tel_intl( nr_tel ) {
      var formatted = nr_tel.toString().replace(/^(\d{4})(\d{3})(\d{3}).*/, '004 $1 $2 $3')
      return formatted ;
    },
    // run maintenance tasks on a timer
    setupMaintenance() {
      this.itv = setInterval( () => {
        this.getChannels(); // update channels
        this.getSongs( this.channel ); // update channel tracks
      }, 1000 * 30 );
    },

    // set an erro message
    setError( key, err ) {
      let errors = Object.assign( {}, this.errors );
      errors[ key ] = String( err || '' ).trim();
      if ( err ) console.warn( 'ERROR('+ key +'):', err );
      this.errors = errors;
    },

    // clear all error messages
    clearError( key ) {
      let errors = Object.assign( {}, this.errors );
      delete errors[ key ];
      this.errors = errors;
    },

    // check if an error has been set for a key
    checkError( key ) {
      return ( key && Object.prototype.hasOwnProperty.call( this.errors, key ) && this.errors[ key ] );
    },

    // clear all error messages
    clearErrors() {
      Object.keys( this.errors ).forEach( key => {
        this.errors[ key ] = '';
      });
    },

    // show player when app is mounted
    initPlayer() {
      document.querySelector( '#_spnr' ).style.display = 'none';
      document.querySelector( '#player-wrap' ).style.opacity = '1';
      document.addEventListener( 'visibilitychange', e => { this.visible = ( document.visibilityState === 'visible' );  if(process.env.NODE_ENV === "development")console.log(e); } );
      //window.addEventListener( 'hashchange', e => { this.applyRoute( window.location.hash );    if(process.env.NODE_ENV === "development")console.log(e); } );
      window.addEventListener( 'hashchange', e => { this.applyRoute( window.location.pathname );    if(process.env.NODE_ENV === "development")console.log(e); } );
      window.addEventListener( 'keydown', this.onKeyboard );
      this.init = true;
    },

    // reset selected channel
    resetPlayer() {
      this.closeAudio();
      this.clearErrors();
      this.channel = {};
      this.melodii = [];
    },

    // try resuming stream problem if possible
    tryAgain() {
      if ( this.hasError( 'support' ) ) {
        this.flushErrors();
        setTimeout( this.setupAudio, 1 );
      } else {
        this.clearErrors();
        this.playChannel(this.channel);
      }
    },

    // when logo is clicked, goto home
    gotoHome() {
      let VUE_APP_URL = this.getWebsiteURL();
      console.log("Website URL"+ VUE_APP_URL);
      this.setRoute(VUE_APP_URL);
    },

    // show/hide the sidebar
    toggleSidebar( toggle ) {
      this.sidebar = ( typeof toggle === 'boolean' ) ? toggle : false;
    },

    // show sidebar at startup if there are no errors
    initSidebar() {
      if ( this.hasError ) return;
      this.toggleSidebar( true );
    },

    // toggle stream playback for current selected channel
    togglePlay() {
      if ( this.loading ) return;
      if ( this.playing ) return this.closeAudio();
      return this.playChannel( this.channel );
    },

    // load last sort options from store
    loadSortOptions() {
      const opts = _store.get( 'sorting_data' );
      if ( opts && opts.param ) this.sortParam = opts.param;
      if ( opts && opts.order ) this.sortOrder = opts.order;
    },

    // toggle sort order
    toggleSortOrder() {
      this.sortOrder = ( this.sortOrder === 'asc' ) ? 'desc' : 'asc';
    },

    // apply sorting and toggle order
    sortBy( param, order ) {
      if ( this.sortParam === param ) { this.toggleSortOrder(); }
      else { this.sortOrder = order || 'asc'; }
      this.sortParam = param;
      _store.set( 'sorting_data', { param: this.sortParam, order: this.sortOrder } );
    },

    // load saved favs list from store
    loadFavorites() {
      const favs = _store.get( 'favorites_data' );
      if ( !Array.isArray( favs ) ) return;
      this.favorites = favs;
    },

    // save favs to a .m3u file
    saveFavorites() {
      let data = '#EXTM3U';
      for ( let id of this.favorites ) {
        const channel = this.channels.filter( c => ( c.id === id ) ).shift();
        if ( !channel ) continue;
        data += '\n\n';
        data += `#EXTINF:0,${channel.nume} [RadioTEL]\n`;
        data += `${channel.mp3file}`;
      }
      const elm = document.createElement( 'a' );
      elm.setAttribute( 'href', 'data:audio/mpegurl;charset=utf-8,'+ encodeURIComponent( data ) );
      elm.setAttribute( 'download', 'radiotel_favorites.m3u' );
      elm.setAttribute( 'target', '_blank' );
      document.body.appendChild( elm );
      setTimeout( () => elm.click(), 100 );
      setTimeout( () => elm.remove(), 1000 );
    },

    // toggle favorite channel by id
    toggleFavorite( id, toggle ) {
      let favs = this.favorites.slice();
      favs = favs.filter( fid => ( fid !== id ) );
      if ( toggle ) favs.push( id );
      this.favorites = favs;
      this.updateCurrentChannel();
      _store.set( 'favorites_data', favs );
    },

    // setup audio routing and stream events
    setupAudio() {
      const a = _audio.setupAudio();
      if ( !a ) {
        return this.setError( 'support', '' +
            'Web Audio is not supported by your browser. ' +
            'Please consider upgrading, or installing a better web browser.'
        );
      }

      a.addEventListener( 'waiting', e => {
        this.playing = false;
        this.loading = true;
        if(process.env.NODE_ENV === "development")
          console.log(e);
      });
      a.addEventListener( 'playing', e => {
        //this.setError( 'stream', '' );
        this.clearError( 'stream' );
        this.playing = true;
        this.loading = false;
        if(process.env.NODE_ENV === "development")
          console.log(e);
      });
      a.addEventListener( 'ended', e => {
        this.playing = false;
        this.loading = false;
        if(process.env.NODE_ENV === "development")
          console.log(e);
      });
      a.addEventListener( 'error', e => {
        this.closeAudio();
        this.setError( `stream`, `Postul selectat (${this.channel.nume}) nu a putut fi redat, sau s-a oprit încărcarea din cauza unei probleme de rețea.  `  );
        this.playing = false;
        this.loading = false;
        if(process.env.NODE_ENV === "development")
          console.log(e);
      });
    },

    // close active audio
    closeAudio() {
      _audio.stopAudio();
      this.playing = false;
    },

    // setup animation canvas
    setupCanvas() {
      //_scene.setupCanvas();
    },

    // audio visualizer animation loop
    updateCanvas() {
      this.anf = requestAnimationFrame( this.updateCanvas );
      if ( !this.visible ) return;
      //const freq = _audio.getFreqData();
      //_scene.updateObjects( freq );
    },

    // get channels data from api
    getChannels( sidebar ) {
      _radiotel.getChannels( ( err, channels ) => {
        if ( err ) return this.setError( 'channels', err );
        if ( sidebar ) this.toggleSidebar( true );
        this.channels = channels;
        //this.setError( 'channels', '' );
        this.clearError( 'channels' );
        this.updateCurrentChannel();
        if(process.env.NODE_ENV === "development")
          console.log("getChannels hash: " + window.location.hash)
        //this.applyRoute( window.location.hash );
        this.applyRoute( window.location );
      });
    },

    // get melodii list for a channel from api
    getSongs( channel, cb ) {
      if(process.env.NODE_ENV === "development")
        console.log("getSongs: " + channel.songsurl)
      if ( !channel || !channel.id || !channel.songsurl ) return;
      if ( !this.isCurrentChannel( channel ) ) { this.melodii = []; this.track = {}; }
      if(process.env.NODE_ENV === "development")
        console.log("getSongs: " + channel.id)

      //luam data curenta
      let last_song_datetime = new Date();
      if(process.env.NODE_ENV === "development")
        console.log("acum: " + last_song_datetime)
      let moment      = require('moment');
      let acum_1_ora  = moment(last_song_datetime).subtract(1, 'hours')
      acum_1_ora      = moment(acum_1_ora).format("YYYY-MM-DD HH:MM:SS")
      if(process.env.NODE_ENV === "development")
        console.log("acum_1_ora: " + acum_1_ora )

      _radiotel.getSongs( channel, ( err, melodii ) => {
        if ( err ) return this.setError( 'melodii', err );
        if ( typeof cb === 'function' ) cb( melodii );

        if(process.env.NODE_ENV === "development")
          console.log("getSongs nr melodii gasite: " + melodii.length)
        this.track = melodii.shift();
        last_song_datetime =  moment(this.track.data).format("YYYY-MM-DD HH:MM:SS")
        if(process.env.NODE_ENV === "development")
          console.log("getSongs melodii gasite: " + last_song_datetime)
        if(last_song_datetime < acum_1_ora) {
          if (process.env.NODE_ENV === "development")
            console.log("getSongs ultima melodie e prea veche: " + last_song_datetime)
          this.track = {};
        }
        else
          this.melodii = melodii.slice( 0, 3 );
        this.setError( 'melodii', '' );
      });
    },

    // checks is a channel is currently selected
    isCurrentChannel( channel ) {
      if ( !channel || !channel.id || !this.channel.id ) return false;
      if ( this.channel.id !== channel.id ) return false;
      return true;
    },

    // update data for current selected channel
    updateCurrentChannel() {
      for ( let c of this.channels ) {
        // see if channel has been saved as a favorite
        c.favorite = ( this.favorites.indexOf( c.id ) >= 0 );
        // see if channel is currently selected
        if ( this.isCurrentChannel( c ) ) {
          this.channel = Object.assign( this.channel, c );
          c.active = true;
        }
      }
    },

    // wrapper for loading and playing a new audio stream source
    playAudioStream( stream ) {
      this.clearErrors();
      this.loading = true; // spinner
      _audio.setVolume( this.volume );
      _audio.playSource( stream );
    },

    // play audio stream for a channel
    playChannel( channel ) {
      if(process.env.NODE_ENV === "development")
        console.log("autoplay: " + channel.autoplay)
      if ( this.playing || !channel || !channel.mp3file ) return;

      // fix to get around AudioContext auto-play policy in chrome
      if ( _audio.getState( 'suspended' ) ) {
        _audio.resumeAudio( () => this.playAudioStream( channel.mp3file ) );
      } else {
        if(process.env.NODE_ENV !== "development")
          this.playAudioStream( channel.mp3file );
      }
    },

    // select a channel to play
    selectChannel( channel ) {
      if ( !channel || !channel.id ) return;
      if ( this.isCurrentChannel( channel ) ) return;
      this.closeAudio();
      this.toggleSidebar( false );
      this.playChannel( channel );
      this.getSongs( channel );
      this.channel = channel;
    },

    getWebsiteURL() {
      let VUE_APP_URL = 'https://www.radiotel.org';

      if(process.env.NODE_ENV === "development")
        VUE_APP_URL = "http://10.5.5.12:8080"

      return VUE_APP_URL;
    },

    // set station route
    setRoute( route ) {
      //route = '/'+ String( route || '' ).replace( /^[\#\/]+|[\/]+$/g, '' ).trim();
      //route = '/'+ String( route || '' ).replace( /^[\\#\\/]+|[\\/]+$/g, '' ).trim();
      //route = '/'+ String( route || '' ).replace( /^$/g, '' ).trim();
      let VUE_APP_URL = this.getWebsiteURL();
      if(process.env.NODE_ENV === "development")
        console.log("route: " + route)
      if(process.env.NODE_ENV === "development")
        console.log("pathname: " + window.location.pathname)
      if(process.env.NODE_ENV === "development")
        console.log("pathname: " + VUE_APP_URL + route)

      //window.location.hash = route;
      //window.location = VUE_APP_URL + route;
      window.location = route;

    },

    // parse url hash route actions
    applyRoute( route ) {
      //route = 'post-radio/magicfm';
      route = window.location.pathname
      //const data   = String( route || '' ).replace( /^[\#\/]+|[\/]+$/g, '' ).trim().split( '/' );
      const data   = String( route || '' ).replace( /^[\\#\\/]+|[\\/]+$/g, '' ).trim().split( '/' );
      //const data   = String( route || '' ).replace( /^$/g, '' ).trim().split( '/' );
      //const data = 'post-radio/magicfm';
      if(process.env.NODE_ENV === "development")
        console.log("applyRoute route: " + route)
      if(process.env.NODE_ENV === "development")
        console.log("applyRoute data: " + data)
      if(process.env.NODE_ENV === "development")
        console.log("location.pathname: " + window.location.pathname)

      const action = data.length ? data.shift() : '';
      const param  = data.length ? data.shift() : '';

      if ( !action ) {
        this.closeAudio();
        this.resetPlayer();
        return;
      }
      if ( action === 'post-radio' && param ) {
        const channel = this.channels.filter( c => ( c.id === param ) ).shift();
        this.selectChannel( channel );
        return;
      }
    },

    // on keyboard events
    onKeyboard( e ) {
      const k = e.key || '';
      if ( k === ' ' && this.channel.id ) return this.togglePlay();
      if ( k === 'Enter' ) return this.toggleSidebar( true );
      if ( k === 'Escape' ) return this.toggleSidebar( false );
    },

    // start tracking playback time
    startClock() {
      this.stopClock();
      this.timeStart = Date.now();
      this.timeItv = setInterval( this.updateClock, 1000 );
      this.updateClock();
    },

    // update tracking playback time
    updateClock() {
      let p = n => ( n < 10 ) ? '0'+n : ''+n;
      let elapsed = ( Date.now() - this.timeStart ) / 1000;
      let seconds = Math.floor( elapsed % 60 );
      let minutes = Math.floor( elapsed / 60 % 60 );
      let hours   = Math.floor( elapsed / 3600 );
      this.timeDisplay = p( hours ) +':'+ p( minutes ) +':'+ p( seconds );
    },

    // stop tracking playback time
    stopClock() {
      if ( this.timeItv ) clearInterval( this.timeItv );
      this.timeItv = null;
    },

    // clear timer refs
    clearTimers() {
      if ( this.sto ) clearTimeout( this.sto );
      if ( this.itv ) clearInterval( this.itv );
      if ( this.anf ) cancelAnimationFrame( this.anf );
    },
  },

  // on app mounted
  mounted() {
    this.loadSortOptions();
    this.loadFavorites();
    this.getChannels( true );
    this.setupAudio();
    //this.setupCanvas();
    //this.updateCanvas();
    this.setupMaintenance();
    this.initPlayer();
  },

  // on app destroyed
  destroyed() {
    this.closeAudio();
    this.clearTimers();
  }

}
</script>

