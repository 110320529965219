import Vue from 'vue'
import App from './App.vue'
//import './js/favorite';
import * as filters from './utils/filters'

// register global utility filters.
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})


const app1 = new Vue({
  el: '#app',
  render: h => h(App)
})
app1.$mount('#app')
