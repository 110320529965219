/**
 * Vue component used to toggle channel favorite
 */
<template>
    <button class="fav-btn" @click.stop="$emit( 'change', id, !active )">
      <i v-if="active" class="fa fa-heart text-primary fx fx-drop-in" key="on"></i>
      <i v-else class="fa fa-heart-o fx fx-drop-in" key="off"></i>
    </button>
</template>

<script>
export default {
  name: 'FavBtn',
  props: {
    id: { type: String, default: '', required: true },
    active: { type: Boolean, default: false },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h6 {
  margin: 40px 0 0;
}
</style>
