/**
 * RadioTEL API handler
 */
import axios from 'axios';
/*import posturi from '/posturi.json';
    const error  = 'A existat o problema la incarcarea listei de posturi de radio de la RadioTEL.';
    const list = this._parseChannels( posturi.channels );
    if ( !list.length ) return callback( error, [] );
    return callback( null, list );*/

export default {

  // get channels data from api
  getChannels( callback ) {

    const apiurl = 'https://radiotel.org/posturi.json';
    const error  = 'A existat o problema la incarcarea listei de posturi de radio de la RadioTEL.';
    if(process.env.NODE_ENV === "development")
      console.log("getChannels");
    axios.get( apiurl ).then( res => {
      const list = this._parseChannels( res.data.channels );
      if ( !list.length ) return callback( error, [] );
      return callback( null, list );
    })
    .catch( e => {
      return callback( error + String( e.message || '' ), [] );
    });
  },

  // fetch melodii for a channel
  getSongs( channel, callback ) {
    const apiurl = channel.songsurl || '';
    const title  = channel.nume || '...';
    const error  = 'A existat o problema la incarcarea listei de melodii pentru postul '+ title +' de la RadioTEL.';

    axios.get( apiurl ).then( res => {
      if ( !res.data.melodii ) return callback( error, [] );
      return callback( null, res.data.melodii );
    })
    .catch( e => {
      return callback( error + String( e.message || '' ), [] );
    });
  },

  // parse channels list from api response
  _parseChannels( channels ) {
    let output = [];
    if ( Array.isArray( channels ) ) {
      for ( let c of channels ) {
        //if ( !Array.isArray( c.playlists ) ) continue;
        //if(process.env.NODE_ENV === "development")
          //console.log(c.twitter);
      let VUE_APP_URL = 'https://www.radiotel.org';

      if(process.env.NODE_ENV === "development")
        VUE_APP_URL = "http://10.5.5.12:8080"

        c.plsfile   = "";//c.playlists.filter( p => ( p.format === 'mp3' && /^(highest|high)$/.test( p.quality ) ) ).shift().url || '';
//        c.mp3file   = "http://178.32.62.172:9917/stream";//https://live.magicfm.ro/magicfm.aacp";//'http://ice1.somafm.com/'+ c.id +'-128-mp3';
//        c.mp3file   = "http://a.fmradiomanele.ro:8054/stream.mp3";
        c.songsurl  = 'https://www.radiotel.org/melodii/'+ c.id +'.json';
//        c.infourl   = 'https://www.radiotel.org/'+ c.id +'/';
        c.image   = "https://www.radiotel.org/img/posturi/"+c.imagine;
        c.mp3file   = 'https://www.radiotel.org/'+ c.id ;
        //c.twitter   = c.twitter ? 'https://twitter.com/@'+ c.twitter : '';
        c.facebook  = c.facebook ?  c.facebook : '';
        c.twitter   = c.twitter ?  c.twitter : '';
        c.route     = VUE_APP_URL + '/post-radio/'+ c.id;
        c.listeners = c.ascultatori | 0;
        c.updated   = c.updated | 0;

        c.favorite  = false;
        c.active    = false;
        output.push( c );
      }
    }
    return output;
  }
}
